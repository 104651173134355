<template>
  <div>
    <h2 style="text-align: center;">Đăng Nhập</h2>
    <form @submit.prevent="handleLogin" class="container">
      <div>
        <label for="email" class="col-1">Email:</label>
        <input class="col-3 m-1"  placeholder="Email" type="email" v-model="email" required />
      </div>
      <div>
        <label for="password" class="col-1">Mật khẩu:</label>
        <input class="col-3 m-1" placeholder="Password" type="password" v-model="password" required />
      </div>
      <a style="margin-left:180px;">Quên mật khẩu?</a><br>
      <button type="submit" class="bg-primary border-0 rounded p-1 col-3 m-2"><h5>Đăng Nhập</h5></button>
      <p style="margin-left: 100px;">Chưa có tài khoản? <a @click="switchToRegister">Đăng ký</a></p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    handleLogin() {
      // Xử lý đăng nhập
      alert('Đăng nhập thành công');
    },
    switchToRegister() {
      this.$emit('switch-view', 'register');
    }
  }
};
</script>
<style scoped>
  .container{
    margin-left: 550px;
  }
  button{
    margin-left: 80px !important;
    padding-top: 10px !important;
  }
  button:hover{
    transform: scale(1.05);
  }
  a{
    cursor: pointer;
  }
</style>