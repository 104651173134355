import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home.vue';
import CartPage from '@/components/CartPage.vue';
import gioithieu from '@/components/gioithieu.vue';
import laptop from '@/components/laptop.vue';
import PC from '@/components/PC.vue';
import login from '@/components/login.vue';
import Case from '@/components/case.vue';
import Display from '@/components/Display.vue';
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
  {path:'/Cart',name:'Cart', component: CartPage},
  {path:'/gioithieu', name:'gioithieu',component: gioithieu},
  {path:'/Laptop',name:'Laptop',component:laptop},
  {path:'/PC',name:'PC',component:PC},
  {path:'/login',name:'login',component:login},
  {path:'/case',name: 'case',component: Case},
  {path:'/display',name:'display',component: Display}
  ],
});
export default router;
