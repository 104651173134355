<template>
   <header>
         <h1>Shop Long Chính</h1>      
         <div class="wrapper"></div>
         <div id="header">
             <nav class="navbar navbar-expand-lg navbar-dark" style="padding-bottom:0px !important;">
                 <div class="container">
                  <router-link to="/login" :key="$route.fullPath" class="nav-link">
                   <a class="navbar-brand logo" id="login-btn">
                         <img src="@/assets/img/account-icon-png-19.jpg">
                     </a>
                     </router-link>                    
                     <!-- Navbar items -->
                     <div class="collapse navbar-collapse" id="navbarNav">
                         <ul id="main-menu" class="navbar-nav ms-auto">
                             <li class="nav-item">
                                <router-link to="/" class="nav-link"><i class="bi bi-house" style="font-size: 1.5rem;"></i>Trang Chủ</router-link>
                             </li>
                             <li class="nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" id="htmlDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-laptop" style="font-size: 1.5rem;"></i>Sản Phẩm</a>
                                 <ul class="dropdown-menu sub-menu" aria-labelledby="htmlDropdown">
                                     <li>
                                         <router-link to="/Laptop" :key="$route.fullPath" class="nav-link">Laptop</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <li>
                                      <router-link to="/PC" :key="$route.fullPath" class="nav-link">PC</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <router-link to="/case" :key="$route.fullPath" class="nav-link">Phím & Chuột</router-link>
                                     <router-link to="/display" :key="$route.fullPath" class="nav-link">Màn hình</router-link>
                                     <li><a class="dropdown-item" role="button">khác</a></li>
                                 </ul>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/Cart" :key="$route.fullPath" class="nav-link"><i class="bi bi-cart2" style="font-size: 1.5rem;"></i>Giỏ Hàng</router-link>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/gioithieu" :key="$route.fullPath" class="nav-link"><i class="bi bi-info-circle" style="font-size: 1.5rem;"></i>Giới Thiệu</router-link>
                             </li>
                         </ul>
                     </div>
                 </div>
             </nav>
         </div>
     </header>
      <img :src="require('@/assets/img/BUILD-PC-MSI-NHAN-QUA-HAP-DAN-songphuong.vn-02.jpg')" alt="description" style="height:500px;width:100%;">
      <div class="content">
        <div class="row justify-content-center p-1" style="background-color: rgb(15, 15, 102);">
            <button class="border border-success col-1 m-1">
                <img :src="require(`@/assets/img/asus.png`)" alt="description" style="width:50px" >
            </button>
            <button class="border border-success col-1 m-1">
                <b><h4><i>MSI</i></h4></b>
            </button>
            <button class="border border-success col-1 m-1">
                <img :src="require('@/assets/img/lenovo.png')" alt="description" style="width:50px" >
            </button>
            <button class="border border-success col-1 m-1">
                <img :src="require('@/assets/img/dell.png')" alt="description" style="width:50px" >
            </button>
            <button class="border border-success col-1 m-1">
                <img :src="require('@/assets/img/acer.png')" alt="description" style="width:50px" >
            </button>
        </div>
      </div>
    <product />
      <footer style="float: left;">
        <p>&copy; 2024 Shop Long&chính. Bán hàng tận tâm.</p>
    </footer>
</template>
<script>
import product from './product.vue';
export default {
    props: {
    },
      mounted(){
          // Đổi màu nền body khi component được hiển thị
      document.body.style.backgroundColor = '#f0f8ff'; // Màu aliceblue
      },
      beforeUnmount() {
      // Khôi phục lại màu nền mặc định khi rời khỏi component
      document.body.style.backgroundColor = '';
    },
      name: 'Laptop',
      components:{
        product,
      }
    };
  </script>
<style scoped>

</style>