<template>
    <header>
         <h1>Shop Long Chính</h1>      
         <div class="wrapper"></div>
         <div id="header">
             <nav class="navbar navbar-expand-lg navbar-dark" style="padding-bottom:0px !important;">
                 <div class="container">
                  <router-link to="/login" :key="$route.fullPath" class="nav-link">
                   <a class="navbar-brand logo" id="login-btn">
                         <img src="@/assets/img/account-icon-png-19.jpg">
                     </a>
                     </router-link>                    
                     <!-- Navbar items -->
                     <div class="collapse navbar-collapse" id="navbarNav">
                         <ul id="main-menu" class="navbar-nav ms-auto">
                             <li class="nav-item">
                                <router-link to="/" class="nav-link"><i class="bi bi-house" style="font-size: 1.5rem;"></i>Trang Chủ</router-link>
                             </li>
                             <li class="nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" id="htmlDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-laptop" style="font-size: 1.5rem;"></i>Sản Phẩm</a>
                                 <ul class="dropdown-menu sub-menu" aria-labelledby="htmlDropdown">
                                     <li>
                                         <router-link to="/Laptop" :key="$route.fullPath" class="nav-link">Laptop</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <li>
                                      <router-link to="/PC" :key="$route.fullPath" class="nav-link">PC</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <router-link to="/case" :key="$route.fullPath" class="nav-link">Phím & Chuột</router-link>
                                     <router-link to="/display" :key="$route.fullPath" class="nav-link">Màn hình</router-link>
                                     <li><a class="dropdown-item" role="button">khác</a></li>
                                 </ul>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/Cart" :key="$route.fullPath" class="nav-link"><i class="bi bi-cart2" style="font-size: 1.5rem;"></i>Giỏ Hàng</router-link>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/gioithieu" :key="$route.fullPath" class="nav-link"><i class="bi bi-info-circle" style="font-size: 1.5rem;"></i>Giới Thiệu</router-link>
                             </li>
                         </ul>
                     </div>
                 </div>
             </nav>
         </div>
     </header>
     <div class="containet mt-1">
        <div class="row mx-auto" style="max-width: 800px;">
            <select class="col-3 m-1 p-1">
            <option selected> mức giá</option>
            <option value="10000">5,000,000 - 10,000,000 VNĐ</option>
            <option value="20000">10,000,000 - 15,000,000 VNĐ</option>
            <option value="50000">15,000,000 - 20,000,000 VNĐ</option>
            <option value="100000">20,000,000 - 20,000,000 VNĐ</option>
            <option value="200000">30,000,000 - 40,000,000 VNĐ</option>
            <option value="500000">40,000,000 - 50,000,000 VNĐ</option>
        </select>
        <button class="col-2">Tìm kiếm</button>
        </div>
    </div>
</template>
<script>
  export default {
    mounted(){
        // Đổi màu nền body khi component được hiển thị
    document.body.style.backgroundColor = '#f0f8ff'; // Màu aliceblue
    },
    beforeUnmount() {
    // Khôi phục lại màu nền mặc định khi rời khỏi component
    document.body.style.backgroundColor = '';
  },
    name: 'case',
  };
  </script>
<style scoped>
.col-5{
    margin-top: 50px !important;
    background-color: rgb(246, 248, 250);
}
.row button{
    margin-left: 150px !important;
}
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Wrapper chứa tất cả nội dung */
#wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Chiều cao tối thiểu là 100% của viewport */
}

/* Nội dung chính sẽ chiếm hết không gian còn lại */
.content {
    flex-grow: 1;
}

/* Footer luôn nằm ở cuối trang */
footer {
    background-color: rgb(22, 22, 84);
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    margin-top: 20px;
}
</style>