<template>
  <div id="app1" class="container-fluid">
    <section class="row product-list">
      <article class="col-md-4 col-sm-6 product-item" v-for="product in products" :key="product.id">
        <div class="card mb-4">
          <img :src="product.image" :alt="product.name" class="card-img-top img-fluid">
          <div class="card-body">
            <h2 class="card-title">{{ product.name }}</h2>
            <p class="card-text">Giá: {{ product.price.toLocaleString() }} VND</p>         
            <button @click="handleAddToCart(product)" class="btn btn-primary">
              Thêm vào giỏ hàng
            </button>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      products: [
        { id: 1, name: 'Laptop X1 Carbon', price: 10000000, image: 'img/m1.jfif' },
        { id: 2, name: 'MacBook Pro', price: 10000000, image: 'img/m2.jpeg' },
        { id: 3, name: 'HP Spectre', price: 10000000, image: 'img/m3.jfif' },
        { id: 4, name: 'Acer Nitro 5', price: 10000000, image: 'img/m4.jfif' },
        { id: 5, name: 'Vibo X-104', price: 10000000, image: 'img/m5.jpg' },
        { id: 6, name: 'HP Spectre', price: 10000000, image: 'img/a1.png' },
      ]
    };
  },
  methods: {
    ...mapActions(['addToCart']), // Kết nối với action Vuex
    handleAddToCart(product) { // Đổi tên hàm
      this.addToCart(product); // Gọi hành động từ Vuex
      alert(`Bạn đã thêm ${product.name} vào giỏ hàng thành công!`);
    }
  }
};
</script>
