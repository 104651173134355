<template>
    <header>
         <h1>Shop Long Chính</h1>      
         <div class="wrapper"></div>
         <div id="header">
             <nav class="navbar navbar-expand-lg navbar-dark" style="padding-bottom:0px !important;">
                 <div class="container">
                  <router-link to="/login" :key="$route.fullPath" class="nav-link">
                   <a class="navbar-brand logo" id="login-btn">
                         <img src="@/assets/img/account-icon-png-19.jpg">
                     </a>
                     </router-link>                    
                     <!-- Navbar items -->
                     <div class="collapse navbar-collapse" id="navbarNav">
                         <ul id="main-menu" class="navbar-nav ms-auto">
                             <li class="nav-item">
                                <router-link to="/" class="nav-link"><i class="bi bi-house" style="font-size: 1.5rem;"></i>Trang Chủ</router-link>
                             </li>
                             <li class="nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" id="htmlDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-laptop" style="font-size: 1.5rem;"></i>Sản Phẩm</a>
                                 <ul class="dropdown-menu sub-menu" aria-labelledby="htmlDropdown">
                                     <li>
                                         <router-link to="/Laptop" :key="$route.fullPath" class="nav-link">Laptop</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <li>
                                      <router-link to="/PC" :key="$route.fullPath" class="nav-link">PC</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <router-link to="/case" :key="$route.fullPath" class="nav-link">Phím & Chuột</router-link>
                                     <router-link to="/display" :key="$route.fullPath" class="nav-link">Màn hình</router-link>
                                     <li><a class="dropdown-item" role="button">khác</a></li>
                                 </ul>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/Cart" :key="$route.fullPath" class="nav-link"><i class="bi bi-cart2" style="font-size: 1.5rem;"></i>Giỏ Hàng</router-link>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/gioithieu" :key="$route.fullPath" class="nav-link"><i class="bi bi-info-circle" style="font-size: 1.5rem;"></i>Giới Thiệu</router-link>
                             </li>
                         </ul>
                     </div>
                 </div>
             </nav>
         </div>
     </header>
      <div class="content col-9">
      <h3><b>Shop Long & Chính</b></h3>
      <h4><b>Số điện thoại:</b> 0358112298</h4>
      <h4><b>Email:</b> Nguyenlong331188@gmail.com</h4>
      <b> Gear Shop - Đồng hành cùng phong cách của bạn</b><br>

Gear Shop là điểm đến lý tưởng cho những ai đam mê thời trang và phong cách sống hiện đại. Chúng tôi cung cấp đa dạng các sản phẩm từ quần áo, phụ kiện đến các thiết bị công nghệ, giúp bạn hoàn thiện phong cách cá nhân một cách tinh tế và nổi bật. <br>

Với sự chú trọng đến chất lượng và xu hướng, Gear Shop luôn cập nhật những sản phẩm mới nhất, mang đến cho khách hàng trải nghiệm mua sắm tuyệt vời. Dù bạn là người yêu thích sự thanh lịch, năng động hay cá tính, Gear Shop đều có những sản phẩm phù hợp với gu thẩm mỹ của bạn. <br>

Hãy đến với Gear Shop để thể hiện phong cách độc đáo của riêng bạn!
      </div>
    <!-- --------------    -->
    <footer style="float: left;">
        <p>&copy; 2024 Shop Long&chính. Bán hàng tận tâm.</p>
    </footer>
</template>
<script>
export default {
  props: {
  },
    mounted(){
        // Đổi màu nền body khi component được hiển thị
    document.body.style.backgroundColor = '#f0f8ff'; // Màu aliceblue
    },
    beforeUnmount() {
    // Khôi phục lại màu nền mặc định khi rời khỏi component
    document.body.style.backgroundColor = '';
  },
    name: 'gioithieu',
  };
</script>
<style scoped>
.content {
      min-height: 100%; /* Đảm bảo phần nội dung chiếm đủ chiều cao trang */
      padding-bottom: 50px; /* Chừa khoảng trống cho footer */
      padding: 20px;
    }
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgb(22, 22, 84);
    color: white;
    text-align: center;
    padding: 10px 0;
    margin-top: 20px; 
    }
</style>