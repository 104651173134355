<template>
    <header>
         <h1>Shop Long Chính</h1>      
         <div class="wrapper"></div>
         <div id="header">
             <nav class="navbar navbar-expand-lg navbar-dark" style="padding-bottom:0px !important;">
                 <div class="container">
                  <router-link to="/login" :key="$route.fullPath" class="nav-link">
                   <a class="navbar-brand logo" id="login-btn">
                         <img src="@/assets/img/account-icon-png-19.jpg">
                     </a>
                     </router-link>                    
                     <!-- Navbar items -->
                     <div class="collapse navbar-collapse" id="navbarNav">
                         <ul id="main-menu" class="navbar-nav ms-auto">
                             <li class="nav-item">
                                <router-link to="/" class="nav-link"><i class="bi bi-house" style="font-size: 1.5rem;"></i>Trang Chủ</router-link>
                             </li>
                             <li class="nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" id="htmlDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-laptop" style="font-size: 1.5rem;"></i>Sản Phẩm</a>
                                 <ul class="dropdown-menu sub-menu" aria-labelledby="htmlDropdown">
                                     <li>
                                         <router-link to="/Laptop" :key="$route.fullPath" class="nav-link">Laptop</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <li>
                                      <router-link to="/PC" :key="$route.fullPath" class="nav-link">PC</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <router-link to="/case" :key="$route.fullPath" class="nav-link">Phím & Chuột</router-link>
                                     <router-link to="/display" :key="$route.fullPath" class="nav-link">Màn hình</router-link>
                                     <li><a class="dropdown-item" role="button">khác</a></li>
                                 </ul>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/Cart" :key="$route.fullPath" class="nav-link"><i class="bi bi-cart2" style="font-size: 1.5rem;"></i>Giỏ Hàng</router-link>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/gioithieu" :key="$route.fullPath" class="nav-link"><i class="bi bi-info-circle" style="font-size: 1.5rem;"></i>Giới Thiệu</router-link>
                             </li>
                         </ul>
                     </div>
                 </div>
             </nav>
         </div>
     </header>
          <div id="wrap">
        <div class="content">
            <div class="container col-5 border rounded border-black">
                <!-- Icon trạng thái giỏ hàng -->
                <div class="row p-3 col-11 ms-3 m-2 rounded" style="background-color: rgb(242, 205, 211);">
                    <img src="@/assets/img/shopping-bag.png" style="width:60px;background-color: red;" class="img-fluid">
                    _ _ _ _ _ _ _
                    <img src="@/assets/img/valid.png" style="width:60px;">
                    _ _ _ _ _ _ _
                    <img src="@/assets/img/id-card.png" style="width:60px;">
                    _ _ _ _ _ _ _
                    <img src="@/assets/img/checklist (1).png" style="width:60px;">
                </div>
    
                <!-- Danh sách sản phẩm trong giỏ hàng -->
                <ul class="list-group" v-if="cartItems.length > 0" style="margin-left: 25px; padding: 5px;">
                    <li v-for="(item, index) in cartItems" :key="index" class="list-group-item d-flex align-items-center col-11">
                        <img :src="item.image" alt="Product Image" class="img-thumbnail me-3" style="width: 150px; height: auto;">
                        <div class="flex-grow-1">
                            <strong>{{ item.name }}</strong>
                            <span class="float-end">{{ item.price.toLocaleString() }} VND</span>
                            <div class="d-flex align-items-center">
                                <button class="btn btn-secondary btn-sm me-2" @click="decreaseQuantity(item.id)">-</button>
                                <span>{{ item.quantity }}</span>
                                <button class="btn btn-secondary btn-sm ms-2" @click="increaseQuantity(item.id)">+</button>
                                <button class="btn btn-danger btn-sm ms-2" @click="removeFromCart(item.id)">Xóa</button>
                            </div>
                        </div>
                    </li>
                    <li style="list-style-type: none; padding: 10px;">
                        <h4>
                            Tổng: {{ cartTotalPrice }} VND 
                            <button class="col-6 border border-primary pt-2 rounded">Thanh toán</button>
                        </h4>
                    </li>
                </ul>
    
                <!-- Giỏ hàng trống -->
                <div v-else class="text-center row col-11 p-3">
                    <span>Giỏ hàng của bạn đang trống.</span>
                    <button class="col-6 border border-primary pt-2 rounded"><h5>Tiếp tục mua hàng</h5></button>
                </div>
            </div>
        </div>
    
        <!-- Footer -->
        <footer>
            <p>&copy; 2024 Shop Long&Chính. Bán hàng tận tâm.</p>
        </footer>
    </div>
    </template>
    
      <script>
    import { mapGetters, mapActions } from 'vuex';
      export default {
        computed: {
        ...mapGetters(['cartItems','cartTotalPrice']), // Lấy danh sách sản phẩm trong giỏ hàng từ Vuex
      },
      methods: {
        ...mapActions(['removeFromCart', 'increaseQuantity', 'decreaseQuantity']),
      },
        mounted(){
            // Đổi màu nền body khi component được hiển thị
        document.body.style.backgroundColor = '#f0f8ff'; // Màu aliceblue
        },
        beforeUnmount() {
        // Khôi phục lại màu nền mặc định khi rời khỏi component
        document.body.style.backgroundColor = '';
      },
        name: 'Cart',
      };
      </script>
    <style scoped>
    .col-5{
        margin-top: 50px !important;
        background-color: rgb(246, 248, 250);
    }
    .row button{
        margin-left: 150px !important;
    }
    html, body {
        height: 100%;
        margin: 0;
        padding: 0;
    }
    
    /* Wrapper chứa tất cả nội dung */
    #wrap {
        display: flex;
        flex-direction: column;
        min-height: 100vh; /* Chiều cao tối thiểu là 100% của viewport */
    }
    
    /* Nội dung chính sẽ chiếm hết không gian còn lại */
    .content {
        flex-grow: 1;
    }
    
    /* Footer luôn nằm ở cuối trang */
    footer {
        background-color: rgb(22, 22, 84);
        color: white;
        text-align: center;
        padding: 10px 0;
        width: 100%;
        margin-top: 20px;
    }
    </style>>
    
    
    