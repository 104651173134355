<template>
  <div>
    <h2 style="text-align: center;">Đăng Ký</h2>
    <form @submit.prevent="handleRegister"  class="container">
      <div>
        <label for="name"  class="col-1">Tên:</label>
        <input class="col-3 m-1" placeholder="User" type="text" v-model="name" required />
      </div>
      <div>
        <label for="email"  class="col-1">Email:</label>
        <input class="col-3 m-1" placeholder="Email" type="email" v-model="email" required />
      </div>
      <div>
        <label for="password"  class="col-1">Mật khẩu:</label>
        <input class="col-3 m-1" placeholder="Password" type="password" v-model="password" required />
      </div>
      <button type="submit" class="bg-primary border-0 rounded p-1 col-3 m-2">Đăng Ký</button>
      <p  style="margin-left: 100px;">Đã có tài khoản? <a @click="switchToLogin">Đăng nhập</a></p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      password: ''
    };
  },
  methods: {
    handleRegister() {
      // Xử lý đăng ký
      alert('Đăng ký thành công');
    },
    switchToLogin() {
      this.$emit('switch-view', 'login');
    }
  }
};
</script>
<style scoped>
  .container{
    margin-left: 550px;
  }
  button{
    margin-left: 80px !important;
  }
  button:hover{
    transform: scale(1.05);
  }
  a{
    cursor: pointer;
  }
</style>
