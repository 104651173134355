<template>
  <div class="containet mt-1">
        <div class="row mx-auto" style="max-width: 800px;">
            <select class="col-3 m-1 p-1">
            <option selected> mức giá</option>
            <option value="10000">5,000,000 - 10,000,000 VNĐ</option>
            <option value="20000">10,000,000 - 15,000,000 VNĐ</option>
            <option value="50000">15,000,000 - 20,000,000 VNĐ</option>
            <option value="100000">20,000,000 - 20,000,000 VNĐ</option>
            <option value="200000">30,000,000 - 40,000,000 VNĐ</option>
            <option value="500000">40,000,000 - 50,000,000 VNĐ</option>
        </select>
        <button class="col-2">Tìm kiếm</button>
        </div>
    </div>
    <div id="app1" class="container-fluid">
      <section class="row product-list">
        <article class="col-md-4 col-sm-6 product-item" v-for="product in products" :key="product.id">
          <div class="card mb-4">
            <img :src="product.image" :alt="product.name" class="card-img-top img-fluid">
            <div class="card-body">
              <h2 class="card-title">{{ product.name }}</h2>
              <p class="card-text">Giá: {{ product.price.toLocaleString() }} VND</p>         
              <button @click="handleAddToCart(product)" class="btn btn-primary">
                Thêm vào giỏ hàng
              </button>
            </div>
          </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    data() {
      return {
        products: [
{ id: 1, name: 'Alienware Aurora R15 Gaming Desktop (2023) - CORE I7-13700KF | RAM 16GB | 1TB SSD | RTX 4080 16GB | New 100% Full Box', price: 79000000, image: 'img/1.webp' },
{ id: 2, name: 'PC Đồ Họa Core i9-12900KS | RAM 32GB | RTX 4060 Ti 16GB | SSD 512 GB Gen4x4', price: 36500000, image: 'img/2.webp' },
{ id: 3, name: 'PC Gaming All White Core I5-13600K | RTX 3060 12GB | RAM 32GB | SSD 512GB NVMe | PSU 750W', price: 27900000, image: 'img/3.jpg' },
{ id: 4, name: 'Dell Alienware Aurora Ryzen Edition R10 Ryzen 5 3600 | RTX 3060 12GB | 32GB DDR4 | SSD 512GB NVMe', price: 17900000, image: 'img/4.webp' },
{ id: 5, name: 'Workstation Dual Xeon Platinum 8176 | RAM 256GB DDR4 ECC | SSD 1TB NVME | Nvidia Quadro RTX A5000 24G GDDR6', price: 150000000, image: 'img/5.webp' },
{ id: 6, name: 'PC Workstation Core I9-14900K | RTX 4090 | Z790 DDR5 | 64GB RAM | 1TB SSD | 1200W', price: 107900000, image: 'img/6.jpg' },
{ id: 7, name: 'PC Đồ Họa Core i9-12900K | RTX 3060 12GB | RAM 32GB | SSD 512GB NVMe', price: 30900000, image: 'img/7.webp' },
{ id: 8, name: 'PC For Interior Design | Core i5-13600K | RAM 32GB | RTX 3060 12G | SSD 1TB NVMe | Case MIK LV12 MINI FLOW', price: 25500000, image: 'img/8.webp' },
{ id: 9, name: 'PC Vertiv Liebert PSA5 UPS - 1500VA/900W 120V | Line Interactive AVR Tower UPS', price: 4471231, image: 'img/9.jpg' },
{ id: 10, name: 'PC RX 7900 XTX Và Ryzen 7 7800X3D STARFIELD', price: 5696000, image: 'img/10.webp' },
{ id: 11, name: 'PC Cooler Master SHARK X White (Phiên bản giới hạn', price: 93990000, image: 'img/11.jpg' },
{ id: 12, name: 'PC Sneaker X Bundle', price: 29990000, image: 'img/12.png' },
{ id: 13, name: 'PC ASUS ROG Hyperion GR701 BTF Edition', price: 11990000, image: 'img/12.webp' },
{ id: 14, name: 'PC Thermaltake Tower 300 Matcha Green – Micro Tower', price: 3350000, image: 'img/14.png' },
{ id: 15, name: 'PC HYTE Y70 Touch Infinite Snow White', price: 12590000, image: 'img/15.jpg' },
        ]
      };
    },
    methods: {
      ...mapActions(['addToCart']), // Kết nối với action Vuex
      handleAddToCart(product) { // Đổi tên hàm
        this.addToCart(product); // Gọi hành động từ Vuex
        alert(`Bạn đã thêm ${product.name} vào giỏ hàng thành công!`);
      }
    }
  };
  </script>
  