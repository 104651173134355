<template>
    <header>
         <h1>Shop Long Chính</h1>      
         <div class="wrapper"></div>
         <div id="header">
             <nav class="navbar navbar-expand-lg navbar-dark" style="padding-bottom:0px !important;">
                 <div class="container">
                  <router-link to="/login" :key="$route.fullPath" class="nav-link">
                   <a class="navbar-brand logo" id="login-btn">
                         <img src="@/assets/img/account-icon-png-19.jpg">
                     </a>
                     </router-link>                    
                     <!-- Navbar items -->
                     <div class="collapse navbar-collapse" id="navbarNav">
                         <ul id="main-menu" class="navbar-nav ms-auto">
                             <li class="nav-item">
                                <router-link to="/" class="nav-link"><i class="bi bi-house" style="font-size: 1.5rem;"></i>Trang Chủ</router-link>
                             </li>
                             <li class="nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" id="htmlDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-laptop" style="font-size: 1.5rem;"></i>Sản Phẩm</a>
                                 <ul class="dropdown-menu sub-menu" aria-labelledby="htmlDropdown">
                                     <li>
                                         <router-link to="/Laptop" :key="$route.fullPath" class="nav-link">Laptop</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <li>
                                      <router-link to="/PC" :key="$route.fullPath" class="nav-link">PC</router-link>
                                         <ul class="dropdown-menu sub-menu">
                                             <li><a class="dropdown-item" role="button">Asus</a></li>
                                             <li><a class="dropdown-item" role="button">MSI</a></li>
                                             <li><a class="dropdown-item" role="button">Lenovo</a></li>
                                             <li><a class="dropdown-item" role="button">Dell</a></li>
                                             <li><a class="dropdown-item" role="button">Aser</a></li>
                                         </ul>
                                     </li>
                                     <router-link to="/case" :key="$route.fullPath" class="nav-link">Phím & Chuột</router-link>
                                     <router-link to="/display" :key="$route.fullPath" class="nav-link">Màn hình</router-link>
                                     <li><a class="dropdown-item" role="button">khác</a></li>
                                 </ul>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/Cart" :key="$route.fullPath" class="nav-link"><i class="bi bi-cart2" style="font-size: 1.5rem;"></i>Giỏ Hàng</router-link>
                             </li>
                             <li class="nav-item">
                                 <router-link to="/gioithieu" :key="$route.fullPath" class="nav-link"><i class="bi bi-info-circle" style="font-size: 1.5rem;"></i>Giới Thiệu</router-link>
                             </li>
                         </ul>
                     </div>
                 </div>
             </nav>
         </div>
     </header>
   <!--slide show-->
   <div id="img">      
         <img :src="images[currentImage]" alt="Slideshow Image" class="img-center">
         <div class="dot-container">
           <span v-for="(image, index) in images" 
                 :key="index" 
                 class="dot" 
                 :class="{ active: index === currentImage }" 
                 @click="setImage(index)">
           </span>
         </div>
       </div>
    <!--  -->
    <ProductList1 /> <!-- Component danh sách sản phẩm 1 --> 
    <ProductList2 /> <!-- Component danh sách sản phẩm 2 -->
     <!-- --------------    -->
     <footer style="float: left;">
         <p>&copy; 2024 Shop Long&chính. Bán hàng tận tâm.</p>
     </footer>
 </template>
 <script>
 import ProductList1 from './ProductList1.vue'; // Import component ProductList1;
 import ProductList2 from './ProductList2.vue'; // Import component ProductList2;
 export default {
   created () {
   },
   name: 'Home',
   components: {
     ProductList1,
     ProductList2,
   },
   data() {
     return {
       images: [
         '/img/am.jpg',
         '/img/ac2.jpg',
         '/img/am3.jfif',
         '/img/orionx2-e1485734513835-1485787052078.webp'
       ],
       currentImage: 0,
     };
   },
   mounted() {
     this.startSlideshow();
   },
   methods: {
     startSlideshow() {
       setInterval(() => {
         this.currentImage = (this.currentImage + 1) % this.images.length;
       }, 2000); // Chuyển ảnh mỗi 2 giây
     },
     setImage(index) {
       this.currentImage = index;
     },
   }
 };
 </script>
 <style>
 body {
     font-family: Arial, sans-serif;
     margin: 0;
     padding: 0;   
     color: #333;
     background-color: royalblue;
 }
 </style>