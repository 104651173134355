<template>
  <div class="containet mt-1">
        <div class="row mx-auto" style="max-width: 800px;">
            <select class="col-3 m-1 p-1">
            <option selected> mức giá</option>
            <option value="10000">5,000,000 - 10,000,000 VNĐ</option>
            <option value="20000">10,000,000 - 15,000,000 VNĐ</option>
            <option value="50000">15,000,000 - 20,000,000 VNĐ</option>
            <option value="100000">20,000,000 - 20,000,000 VNĐ</option>
            <option value="200000">30,000,000 - 40,000,000 VNĐ</option>
            <option value="500000">40,000,000 - 50,000,000 VNĐ</option>
        </select>
        <button class="col-2">Tìm kiếm</button>
        </div>
    </div>
 <div id="app1" class="container-fluid">
  <section class="row product-list">
    <article
      class="col-6 col-sm-4 col-md-2-4 product-item"
      v-for="product in products"
      :key="product.id"
    >
      <div class="card mb-4 card-custom">
        <div class="image-container">
          <img :src="product.image" :alt="product.name" class="card-img-top img-fluid">
        </div>
        <div class="card-body">
          <b class="card-title">{{ product.name }}</b>
          <p class="card-text">Giá: {{ product.price.toLocaleString() }} VND</p>
          <button @click="handleAddToCart(product)" class="btn btn-primary">
            Thêm vào giỏ hàng
          </button>
        </div>
      </div>
    </article>
  </section>
</div>
  
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            products:[
                { id: 1, name: 'ASUS - TUF Gaming', price: 10000000, image: 'img/laptop1.avif',brand:'ASUS' },
                { id: 2, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 3, name: 'Asus X515MA-C42G0W', price: 10000000, image: 'img/laptop3.jpg',brand:'ASUS' },
                { id: 4, name: 'MSI 15.6" GL62M', price: 10000000, image: 'img/laptop4.jpg',brand:'MSI' },
                { id: 5, name: 'MSI Cyborg Gaming', price: 10000000, image: 'img/laptop5.webp',brand:'MSI' },
                { id: 6, name: 'MSI Modern 14', price: 10000000, image: 'img/laptop6.webp',brand:'MSI' },
                { id: 7, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 8, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 9, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 10, name: 'MSI Modern 14', price: 10000000, image: 'img/laptop6.webp',brand:'MSI' },
                { id: 11, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 12, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 13, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 14, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
                { id: 15, name: 'ASUS X55A-DS91', price: 10000000, image: 'img/laptop2.jpg',brand:'ASUS' },
            ]
        }
    },
    methods: {
    ...mapActions(['addToCart']), // Kết nối với action Vuex
    handleAddToCart(product) { // Đổi tên hàm
      this.addToCart(product); // Gọi hành động từ Vuex
      alert(`Bạn đã mua ${product.name} thành công!`);
    }
  },
}
</script>
<style scoped>
/* Tạo tỷ lệ 2:1 cho thẻ sản phẩm */
.card-custom .image-container {
  position: relative;
  width: 100%;
  padding-bottom: 50%; /* Tạo tỷ lệ 2:1 (chiều cao bằng 1/2 chiều rộng) */
}

.card-custom .image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Đảm bảo hình ảnh lấp đầy khung */
}

/* Đảm bảo nội dung thẻ không bị tràn */
.card-custom .card-body {
  flex: 1;
}

/* Điều chỉnh độ rộng cho sản phẩm để chia thành 5 trên một hàng */
@media (min-width: 768px) {
  .col-md-2-4 {
    flex: 0 0 20%; /* Chiếm 1/5 hàng */
    max-width: 20%;
  }
}

@media (max-width: 768px) {
  /* Điều chỉnh kích thước cho thiết bị nhỏ hơn */
  .col-sm-4 {
    flex: 0 0 33.33%; /* 3 sản phẩm trên 1 hàng với màn hình nhỏ hơn */
    max-width: 33.33%;
  }

  .col-6 {
    flex: 0 0 50%; /* 2 sản phẩm trên 1 hàng với màn hình điện thoại */
    max-width: 50%;
  }
}


</style>